import * as React from "react";
import { makeStyles, Typography } from "@material-ui/core";

import Layout from "../components/layout";


const useStyles = makeStyles((theme) => ({
  message: {
    fontWeight: 600,
    lineHeight: 3,
    height: 300,

    fontSize: "4.44vw",
    "@media(min-width: 1440px)": {
      fontSize: "64px",
    },
    "@media(max-width: 414px)": {
      fontSize: "8.69vw",
    },
  },
}));

const DataSend = () => {
  const classes = useStyles();

  return (
    <Layout>
      <Typography align="center" className={classes.message}>
            Данные не отправлены из-за технической ошибки. Позвоните по телефону пожалуйста
      </Typography>
    </Layout>
  );
};

export default DataSend;